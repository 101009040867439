import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import PreviewCompatibleImage from './../components/PreviewCompatibleImage'

import Layout from '../components/Layout'
// import ImageRow from "../components/ImageRow";

import podcastAngleSVG from '../img/podcast-angle-bg.svg'

import Markdown from 'markdown-to-jsx'

import axios from 'axios'

const ContactPageTemplate = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      message: '',
      location: '',
      website: '',
      buttontext: 'Send',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(key, event) {
    if (key === 'name') {
      this.setState({ name: event.target.value })
    } else if (key === 'email') {
      this.setState({ email: event.target.value })
    } else if (key === 'message') {
      this.setState({ message: event.target.value })
    } else if (key === 'location') {
      this.setState({ location: event.target.value })
    } else if (key === 'website') {
      this.setState({ website: event.target.value })
    }
  }

  handleSubmit(event) {
    event.preventDefault()

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
      },
    }

    const apibody = {
      buttonlabel: 'Contact',
      form: {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
        location: this.state.location,
        website: this.state.website,
      },
    }

    if (!/\S+@\S+\.\S+/.test(apibody.form.email)) {
      this.setState({
        buttontext: 'Invalid email',
      })
    } else if (
      apibody.form.name === '' ||
      apibody.form.name === null ||
      apibody.form.name === undefined
    ) {
      this.setState({
        buttontext: 'Name needed',
      })
    } else if (
      apibody.form.message === '' ||
      apibody.form.message === null ||
      apibody.form.message === undefined
    ) {
      this.setState({
        buttontext: 'Message needed',
      })
    } else {
      axios
        .post(
          'https://europe-west1-curated-stores.cloudfunctions.net/contactEmail?contact=lenaskidynamic',
          apibody
          // ,axiosConfig
        )
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              buttontext: 'Message sent',
              name: '',
              email: '',
              message: '',
              location: '',
              website: '',
            })
            setInterval(() => {
              this.setState({
                buttontext: this.defaultButtonText,
              })
            }, 5000)
          }
        })
        .catch((err) => {
          console.log('Message sending failed')
          console.warn(err)
        })
    }
  }

  render() {
    return (
      <div>
        {/* profilepicture & primaryheadline */}
        <div className="w-100 gc-podcast grid portfolio-pt-ns">
          <div className="gcs2-ns gcs1 gce5 grs1 gre2 z-2 mb-1px">
            <PreviewCompatibleImage
              imageInfo={{
                image: this.props.contactpicture.image,
                alt: this.props.contactpicture.alt,
              }}
            />
          </div>
          <div
            className="db-ns dn gcs1 gce5 grs1 gre2 z-999 bg-100wh"
            style={{ backgroundImage: `url(${podcastAngleSVG})` }}
          ></div>
          <div className="gcs1 gce3-ns gce5 grs1-ns grs2 gre2-ns gre3 g-self-end z-999">
            <div className="w-100 pb3 pt4 pt0-ns ph0-ns ph3">
              <div className="tr-l tc pt3-m pb4-ns pb2 pb2-m ski-dark-blue f2-ns f3 tracked ph4-ns ph3 ph5-m">
                {this.props.contactprimaryheadline.title}
              </div>
              <br />
              <div className="ml5-ns tl-ns tc tracked lh-copy pb4-ns pb3 pb2-m">
                <div className="fw5 f4-ns f5 pb2-ns pb3 tracked ski-dark-silver">
                  {this.props.contactprimaryheadline.description}
                </div>
                <div className="fw4 f5">
                  <div className="">
                    <Markdown>{this.props.contactintro.description}</Markdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* contactquote */}
        <div className="tc pt4-ns pt2 pb4-ns pb2 ph5-ns ph2 ski-dark-blue">
          <div className="f2-ns f3 f3-m tracked cursive">
            {this.props.contactquote.quote}
          </div>
          <div>{this.props.contactquote.quotecredit}</div>
        </div>

        {/* contact form heading */}
        {/* <div className="ph6-ns pt4">
          <div className="tc pt4 pb4 ph5-ns ph2 bg-ski-light-turquoise white">
            <div className="f1-ns f4 tracked-ns">
              {this.props.contactform.heading}
            </div>
          </div>
        </div> */}

        {/* form */}
        <div className="tc mh6-ns mh5-m ph3-ns ph6-ns ph5-m b--ski-medium-turquoise bw1-ns bl-ns bt-ns br-ns bb-ns pv3 mb4">
          <form
            className="grid mt4-ns mt2 ph6-ns ph0-m ph3 grg3 hide-placeholder"
            onSubmit={this.handleSubmit}
          >
            <label className="db-ns dn gcs1 gce2 grs1 gre2 grid content-center pv2 ph2-ns ph4">
              <div className="tl">First & Last Name:</div>
            </label>
            <input
              className="gcs2-ns gcs1 gce3 grs1 gre2 pv2 f5 pa3 b--ski-medium-turquoise input-reset ba lh-solid w-100"
              type="text"
              name="contact-name"
              placeholder="First & Last Name"
              id="contact-name"
              value={this.state.name}
              onChange={(e) => this.handleChange('name', e)}
              required
            />
            <label className="db-ns dn gcs1 gce2 grs2 gre3 grid content-center pv2 ph2-ns ph4">
              <div className="tl">Email:</div>
            </label>
            <input
              className="gcs2-ns gcs1 gce3 grs2 gre3 pv2 f5 pa3 b--ski-medium-turquoise input-reset ba lh-solid w-100"
              type="email"
              name="contact-email"
              placeholder="Email"
              id="contact-email"
              value={this.state.email}
              onChange={(e) => this.handleChange('email', e)}
              required
            />
            <label className="db-ns dn gcs1 gce2 grs3 gre4 grid content-center pv2 ph2-ns ph4">
              <div className="tl">Your Location:</div>
            </label>
            <input
              className="gcs2-ns gcs1 gce3 grs3 gre4 pv2 f5 pa3 input-reset ba b--ski-medium-turquoise lh-solid w-100"
              type="text"
              name="contact-location"
              placeholder="Your Location"
              id="contact-location"
              value={this.state.location}
              onChange={(e) => this.handleChange('location', e)}
            />
            <label className="db-ns dn gcs1 gce2 grs4 gre5 grid content-center pv2 ph2-ns ph4">
              <div className="tl">Website Adress:</div>
            </label>
            <input
              className="gcs2-ns gcs1 gce3 grs4 gre5 pv2 f5 pa3 input-reset ba b--ski-medium-turquoise lh-solid w-100"
              type="text"
              name="contact-website"
              placeholder="Website adress"
              id="contact-website"
              value={this.state.website}
              onChange={(e) => this.handleChange('website', e)}
            />
            <label className="db-ns dn gcs1 gce2 grs5 gre6 grid content-center pv2 ph2-ns ph4">
              <div className="tl pb2">Message:</div>
            </label>
            <textarea
              className="pv2 f5 gcs2-ns gcs1 gce3 pa3 grs5 gre6 input-reset ba b--ski-medium-turquoise lh-solid w-100 h4"
              type="text"
              name="contact-message"
              placeholder="Message"
              id="contact-message"
              value={this.state.message}
              onChange={(e) => this.handleChange('message', e)}
              required
            />
            <div className="pb4-ns pb2-m pb2 w-100 gcs1 gce3 grs6 gre7 grid">
              <input
                type="submit"
                value={this.state.buttontext}
                className="br2 pv1 ph3 ba b--ski-medium-turquoise grow glow mt2 db pointer gjs-end-ns white bg-ski-medium-turquoise"
              />
            </div>
            <div className="pb4-ns pb2-m pb2 w-100 gcs1 gce3 grs7 gre8 tc f5 fw4 tracked ski-medium-turquoise lh-copy grid">
              You are also welcome to contact me via direct mail or social media
            </div>
          </form>
        </div>
      </div>
    )
  }
}

ContactPageTemplate.propTypes = {
  contactpicture: PropTypes.object,
  contactprimaryheadline: PropTypes.object,
  contactintro: PropTypes.object,
  contactquote: PropTypes.object,
  contactform: PropTypes.object,
}

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout
      seo={{
        title: 'Lena Ski - Contact Page',
        keywords: [
          `Create authentic engagement for entrepreneurs and small businesses`,
          `Workshops and team facilitation for sustainability-led innovation`,
          `Executive and team coaching, developing purposeful business habits`,
          `International purpose strategist available via zoom`,
        ],
        description:
          'Get in contact with Lena Ski, creating bespoke solutions based on your business needs',
      }}
      page="contact"
    >
      <ContactPageTemplate
        contactpicture={frontmatter.contactpicture}
        contactprimaryheadline={frontmatter.contactprimaryheadline}
        contactintro={frontmatter.contactintro}
        contactquote={frontmatter.contactquote}
        contactform={frontmatter.contactform}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(fileAbsolutePath: { regex: "/contact/" }) {
      frontmatter {
        contactpicture {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
        contactprimaryheadline {
          description
          title
        }
        contactintro {
          description
        }
        contactquote {
          quote
          quotecredit
        }
        contactform {
          heading
        }
      }
    }
  }
`
